<script setup lang="ts">
export interface Props {
  mode?: 'both' | 'mark' | 'type'
  markBottomFill?: string
  markMiddleFill?: string
  markTopStroke?: string
  outlineMark?: boolean

}

const props = withDefaults(defineProps<Props>(), {
  mode: 'both',
  markBottomFill: '#EA3F32',
  markMiddleFill: '#3805F5',
  markTopStroke: '#FFFD5A',
  outlineMark: false,
})

const showType = props.mode === 'both' || props.mode === 'type'
const showMark = props.mode === 'both' || props.mode === 'mark'
</script>

<template>
  <svg
    viewBox="0 0 179 93" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve" xmlns:serif="http://www.serif.com/"
    style="fill-rule:evenodd;clip-rule:evenodd;stroke-miterlimit:2;"
  >

    <g v-if="showType">
      <clipPath id="_clip1">
        <rect x="63.917" y="11.175" width="114.403" height="50.153" />
      </clipPath>
      <g clip-path="url(#_clip1)">
        <path
          d="M81.248,61.136c-0.962,0 -1.733,-0.192 -2.407,-0.481c-0.673,-0.385 -1.154,-0.867 -1.54,-1.637l-12.706,-26.376c-0.674,-1.348 -0.867,-2.502 -0.481,-3.369c0.288,-0.866 1.155,-1.636 2.503,-2.214c1.347,-0.674 2.502,-0.962 3.368,-0.674c0.867,0.192 1.541,1.059 2.215,2.599l9.048,21.274l8.857,-20.889c0.673,-1.54 1.443,-2.502 2.31,-2.888c0.867,-0.384 2.021,-0.192 3.562,0.578c1.54,0.77 2.406,1.636 2.502,2.599c0.096,0.962 -0.192,2.022 -0.77,3.273l-12.514,26.087c-0.385,0.674 -0.866,1.252 -1.54,1.637c-0.577,0.289 -1.444,0.481 -2.407,0.481Z"
          style="fill:currentColor;fill-rule:nonzero;"
        />
        <path
          d="M116.385,61.328c-2.214,0 -4.332,-0.481 -6.449,-1.347c-2.119,-0.866 -4.044,-2.118 -5.681,-3.658c-1.732,-1.541 -2.983,-3.369 -3.947,-5.584c-0.962,-2.118 -1.443,-4.524 -1.443,-7.027c-0,-2.503 0.481,-4.717 1.347,-6.835c0.867,-2.117 2.118,-4.043 3.755,-5.583c1.636,-1.636 3.465,-2.887 5.583,-3.754c2.118,-0.866 4.332,-1.348 6.739,-1.348c2.406,-0 6.257,0.771 8.856,2.311c2.599,1.54 4.717,3.657 6.353,6.257c1.54,2.599 2.311,5.583 2.311,8.952c-0,3.369 -0.482,5.006 -1.445,7.22c-0.962,2.214 -2.31,4.043 -3.946,5.583c-1.637,1.54 -3.562,2.696 -5.584,3.562c-2.022,0.866 -4.235,1.251 -6.449,1.251Zm-0.096,-8.182c1.54,0 2.983,-0.385 4.427,-1.155c1.444,-0.77 2.6,-1.829 3.466,-3.273c0.867,-1.444 1.347,-3.08 1.347,-5.102c0,-2.022 -0.384,-3.369 -1.154,-4.813c-0.77,-1.348 -1.926,-2.503 -3.273,-3.177c-1.444,-0.77 -2.984,-1.155 -4.813,-1.155c-1.83,0 -3.177,0.385 -4.621,1.251c-1.348,0.771 -2.503,1.925 -3.273,3.273c-0.77,1.348 -1.252,2.985 -1.252,4.717c0,1.733 0.482,3.659 1.348,5.006c0.867,1.444 2.021,2.503 3.466,3.273c1.347,0.77 2.792,1.155 4.332,1.155Z"
          style="fill:currentColor;fill-rule:nonzero;"
        />
        <path
          d="M140.258,20.128c-1.348,0 -2.407,-0.192 -2.984,-0.578c-0.578,-0.384 -1.059,-0.962 -1.155,-1.636c-0.192,-0.674 -0.289,-1.444 -0.289,-2.311c0,-0.866 0,-1.54 0.289,-2.213c0.192,-0.674 0.577,-1.156 1.251,-1.637c0.578,-0.385 1.636,-0.577 2.984,-0.577c1.348,-0 2.407,0.192 2.985,0.577c0.577,0.385 0.962,0.963 1.154,1.637c0.193,0.673 0.29,1.443 0.29,2.31c-0,0.867 -0,1.54 -0.29,2.214c-0.192,0.674 -0.577,1.155 -1.154,1.636c-0.674,0.386 -1.637,0.578 -3.081,0.578Zm0,40.72c-1.252,-0 -2.214,-0.193 -2.792,-0.578c-0.577,-0.385 -0.963,-0.866 -1.059,-1.54c0,-0.578 -0.192,-1.348 -0.192,-2.119l-0,-25.701c-0,-0.77 -0,-1.444 0.192,-2.118c0,-0.578 0.482,-1.155 1.156,-1.444c0.577,-0.385 1.54,-0.577 2.887,-0.577c1.348,-0 2.215,0.192 2.792,0.577c0.578,0.385 0.963,0.866 1.059,1.54c0.096,0.674 0.192,1.348 0.192,2.214l0,25.606c0,0.77 0,1.444 -0.192,2.118c0,0.578 -0.481,1.155 -1.059,1.444c-0.674,0.385 -1.636,0.578 -2.984,0.578Z"
          style="fill:currentColor;fill-rule:nonzero;"
        />
        <path
          d="M148.633,27.637c0.77,-0.674 1.444,-1.156 2.117,-1.348c0.578,-0.192 1.156,-0 1.734,0.192c0.576,0.29 1.25,0.867 2.021,1.733l21.948,25.414c0.77,0.866 1.348,1.54 1.636,2.213c0.289,0.674 0.289,1.252 0.096,1.83c-0.192,0.577 -0.77,1.251 -1.636,2.021c-0.77,0.674 -1.444,1.156 -2.021,1.348c-0.578,0.192 -1.156,0.192 -1.734,-0.096c-0.576,-0.289 -1.25,-0.866 -2.117,-1.733l-22.14,-25.317c-0.77,-0.867 -1.348,-1.637 -1.54,-2.31c-0.29,-0.674 -0.29,-1.252 -0,-1.83c0.288,-0.577 0.77,-1.251 1.636,-2.117Zm27.724,-0c0.866,0.77 1.444,1.444 1.732,2.117c0.289,0.578 0.289,1.252 0,1.83c-0.288,0.673 -0.77,1.443 -1.54,2.31l-21.948,25.413c-0.77,0.867 -1.444,1.445 -2.117,1.733c-0.578,0.288 -1.156,0.288 -1.734,0.096c-0.577,-0.192 -1.251,-0.674 -2.117,-1.348c-0.77,-0.77 -1.348,-1.443 -1.54,-2.021c-0.29,-0.578 -0.29,-1.156 -0,-1.829c0.288,-0.674 0.77,-1.347 1.54,-2.214l21.948,-25.413c1.155,-1.252 2.213,-1.926 2.984,-2.022c0.674,-0.192 1.636,0.288 2.792,1.348Z"
          style="fill:currentColor;fill-rule:nonzero;"
        />
      </g>
    </g>

    <g v-if="showMark && outlineMark">
      <path
        d="M47.58,30.273c0,0 -9.469,2.017 -8.68,-5.523l3.42,-18.063c-0,-0 0.525,-2.017 -1.579,-2.806c-0,0 -1.929,-0.701 -3.069,1.14c-1.14,1.841 -19.991,37.703 -19.991,37.703c0,-0 -0.964,2.718 0.789,3.946c0,-0 0.877,1.051 2.718,0.613l4.823,-1.14c0,0 2.893,-0.789 4.734,1.578c0,0 1.491,1.141 0.965,4.297l-6.05,22.533c0,0 -0.701,1.842 1.403,2.806c-0,0 1.403,0.438 2.718,-1.228l20.956,-41.121c0,-0 1.052,-1.929 -0.614,-3.771c0,0 -1.315,-1.051 -2.367,-0.964l-0.176,0Z"
        style="fill-rule:nonzero;stroke:#000;stroke-width:7.5px;"
      />
      <path
        d="M37.585,9.668l-3.42,18.062c-0.526,2.982 1.754,5.7 4.822,5.612l3.858,-0c2.456,-0.176 4.121,2.367 2.982,4.559l-20.956,41.122c-1.227,2.368 -4.823,0.964 -4.121,-1.579l6.05,-22.532c0.965,-3.508 -2.192,-6.664 -5.699,-5.875l-4.823,1.14c-2.63,0.614 -4.734,-2.192 -3.507,-4.56l19.991,-37.702c1.316,-2.543 5.174,-1.14 4.647,1.666l0.176,-0l-0,0.087Z"
        style="fill-rule:nonzero;stroke:#000;stroke-width:7.5px;"
      />
      <path
        d="M38.549,36.498c-0,0 -9.47,2.017 -8.68,-5.524l3.419,-18.062c0,-0 0.526,-2.017 -1.578,-2.806c-0,0 -1.929,-0.701 -3.069,1.14c-1.14,1.841 -19.991,37.702 -19.991,37.702c-0,0 -0.964,2.719 0.789,3.947c0,-0 0.877,1.051 2.718,0.613l4.823,-1.14c-0,0 2.893,-0.789 4.734,1.578c0,0 1.491,1.141 0.965,4.297l-6.05,22.534c-0,-0 -0.701,1.841 1.402,2.805c0,0 1.404,0.439 2.719,-1.227l20.956,-41.122c-0,-0 1.051,-1.929 -0.614,-3.771c-0,0 -1.315,-1.051 -2.368,-0.964l-0.175,0Z"
        style="stroke:#000;stroke-width:7.5px;stroke-linejoin:miter;"
      />
    </g>
    <g v-if="showMark">
      <path
        d="M47.58,30.273c0,0 -9.469,2.017 -8.68,-5.523l3.42,-18.063c-0,-0 0.525,-2.017 -1.579,-2.806c-0,0 -1.929,-0.701 -3.069,1.14c-1.14,1.841 -19.991,37.703 -19.991,37.703c0,-0 -0.964,2.718 0.789,3.946c0,-0 0.877,1.051 2.718,0.613l4.823,-1.14c0,0 2.893,-0.789 4.734,1.578c0,0 1.491,1.141 0.965,4.297l-6.05,22.533c0,0 -0.701,1.842 1.403,2.806c-0,0 1.403,0.438 2.718,-1.228l20.956,-41.121c0,-0 1.052,-1.929 -0.614,-3.771c0,0 -1.315,-1.051 -2.367,-0.964l-0.176,0Z"
        style="fill-rule:nonzero;" :style="{ fill: markBottomFill }"
      />
      <path
        d="M37.585,9.668l-3.42,18.062c-0.526,2.982 1.754,5.7 4.822,5.612l3.858,-0c2.456,-0.176 4.121,2.367 2.982,4.559l-20.956,41.122c-1.227,2.368 -4.823,0.964 -4.121,-1.579l6.05,-22.532c0.965,-3.508 -2.192,-6.664 -5.699,-5.875l-4.823,1.14c-2.63,0.614 -4.734,-2.192 -3.507,-4.56l19.991,-37.702c1.316,-2.543 5.174,-1.14 4.647,1.666l0.176,-0l-0,0.087Z"
        style="fill-rule:nonzero;" :style="{ fill: markMiddleFill }"
      />
      <path
        d="M38.549,36.498c-0,0 -9.47,2.017 -8.68,-5.524l3.419,-18.062c0,-0 0.526,-2.017 -1.578,-2.806c-0,0 -1.929,-0.701 -3.069,1.14c-1.14,1.841 -19.991,37.702 -19.991,37.702c-0,0 -0.964,2.719 0.789,3.947c0,-0 0.877,1.051 2.718,0.613l4.823,-1.14c-0,0 2.893,-0.789 4.734,1.578c0,0 1.491,1.141 0.965,4.297l-6.05,22.534c-0,-0 -0.701,1.841 1.402,2.805c0,0 1.404,0.439 2.719,-1.227l20.956,-41.122c-0,-0 1.051,-1.929 -0.614,-3.771c-0,0 -1.315,-1.051 -2.368,-0.964l-0.175,0Z"
        style="fill:none;stroke-width:0.88px;stroke-linejoin:miter;" :style="{ stroke: markTopStroke }"
      />
    </g>

  </svg>
</template>
